/* PrintableLayout.css (or your global CSS) */
.printable-layout {
    display: none;
    /* hidden in normal view */
}

@media print {
    body {
        margin: 20px !important;
        visibility: visible !important;
    }

    .printable-layout {
        visibility: visible !important;
        position: relative !important;
        top: auto !important;
        left: auto !important;
    }

    .no-print {
        display: none !important;
    }
}